<template>
    <div class="file-container" ref="fileContainer" @drop.prevent="dropFile" @dragover.prevent="() => {}">
        <el-table class="file-list" v-if="tableHeight != 0" ref="tableRef" :data="fileList" :height="tableHeight"
            :cell-style="{ padding: '5px 0' }">
            <el-table-column prop="name" label="文件名" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.showName"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" width="100" align="center">
            </el-table-column>
            <el-table-column prop="status" label="状态" width="190" align="center">
                <template slot-scope="scope">
                    <div class="status">
                        <img v-if="scope.row.status === 'success'" class="status-img" src="@imgs/upload-success.png" />
                        <img v-else-if="scope.row.status === 'fail'" class="status-img" src="@imgs/upload-fail.png" />
                        <img v-else class="status-img loading" src="@imgs/upload-loading.png" />
                        <span class="status-text" v-if="scope.row.status === 'success'">上传成功</span>
                        <span class="status-text" v-else-if="scope.row.status === 'fail'">文件上传失败</span>
                        <span class="status-text" v-else>上传{{ scope.row.persent }}%</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" width="60" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleRemove(scope.row)" type="text">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <button class="table-upload" @click.prevent="onClickAddFile"> <img class="upload-img" src="@imgs/upload-add.png" />
            上传文件</button>
        <UploadFile ref="uploadFileRef" v-show="fileList.length == 0" class="file-upload" fileName="睡眠报告"
            accept="image/jpeg,image/jpg,image/png,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
            :httpRequest="uploadRequest" :onStart="onUploadStart" :onProgress="onUploadProgress"
            :onSuccess="onUploadSuccess" :onError="onUploadError">
        </UploadFile>
    </div>
</template>

<script>
import { getFileSize, getFileExtension, getPathExtension, getFileName } from '@js/file-tool.js'
import { Button, Input } from 'element-ui'
export default {
    components: {
        UploadFile: () => import('@c/user-main/common/upload-examination-report/upload-file.vue'),
        ElButton: Button,
        ElInput: Input
    },
    props: {
        originFiles: Array,
    },
    data() {
        return {
            fileList: [],
            tempIndex: 1,
            tableHeight: 0
        }
    },
    computed: {
        // tableData() {
        //     return this.fileList.map(el => {
        //         // const fileSize = getFileSize(el)

        //         el.showName = getFileName(el)
        //         if (!el.type) {
        //             const fileType = getFileExtension(el)
        //             el.type = `${fileType}`
        //         }
        //         return el
        //     })
        // },

        originFilesArray: {
            get() {
                return this.originFiles
            },
            set(val) {
                this.$emit('update:originFiles', val)
            }
        }
    },

    watch: {
        // files: {
        //     immediate: true,
        //     handler(files) {
        //         let arr1 = files.map(item => {
        //             item.uid = item.uid || (Date.now() + this.tempIndex++);
        //             item.status = item.status || 'success';
        //             return item;
        //         });
        //         let arr2 = []
        //         this.fileList = arr1 + arr2
        //     }
        // },

        originFiles: {
            handler(newVal, oldVal) {
                this.fileList = this.originFiles.map(item => {
                    const newItem = JSON.parse(JSON.stringify(item))
                    newItem.uid = newItem.uid || (Date.now() + this.tempIndex++);
                    newItem.status = newItem.status || 'success';
                    newItem.showName = `${newItem.name}`;
                    const fileType = getPathExtension(newItem.url)
                    newItem.type = `${fileType}`
                    return newItem;
                });
            },
            immediate: true
        }

    },

    created() {

    },

    mounted() {
        this.tableHeight = (this.$refs.fileContainer?.offsetHeight ?? 300) - 32
    },
    methods: {
        uploadRequest(options) {
            return this.$api.uplodFileRequest(options.file, options.onProgress)
        },

        onUploadStart(rawFile) {
            rawFile.uid = Date.now() + this.tempIndex++;
            let file = {
                status: 'ready',
                name: rawFile.name,
                size: rawFile.size,
                percentage: 0,
                uid: rawFile.uid,
                raw: rawFile,

            };
            file.showName = getFileName(file)
            file.type = getFileExtension(file)
            this.fileList.push(file);
        },

        onUploadSuccess(res, rawFile) {
            const file = this.getFile(rawFile);
            if (file) {
                file.status = 'success';
                file.url = res;
            }
        },

        onUploadError(err, rawFile) {
            console.log(err);
            const file = this.getFile(rawFile);
            file.status = 'fail';
        },

        onUploadProgress(progressEvent, rawFile) {
            // console.log(progressEvent);
            let persent = (progressEvent.loaded / progressEvent.total * 100 | 0)		//上传进度百分比
            // console.log(`上传进度${persent}%`)

            const file = this.getFile(rawFile);
            file.status = 'uploading';
            file.persent = persent || 0;
        },

        getFile(rawFile) {
            let fileList = this.fileList;
            let target;
            fileList.every(item => {
                target = rawFile.uid === item.uid ? item : null;
                return !target;
            });
            return target;
        },

        handleRemove(file) {
            let index = -1
            index = this.originFilesArray.findIndex((item) => item.uid === file.uid)
            if (index !== -1) {
                this.originFilesArray = this.originFilesArray.splice(index, 1)
            }
            index = this.fileList.findIndex((item) => {
                if (file.uid != null) {
                    return item.uid === file.uid
                } else {
                    return item.url === file.url
                }
            })
            console.log(index);
            if (index !== -1) {
                this.fileList.splice(index, 1)
            }
        },

        onClickAddFile() {
            this.$refs.uploadFileRef?.onClickUpload()
        },

        dropFile(event) {
            console.log(123);
            const files = event.dataTransfer.files;
            this.$refs.uploadFileRef?.handleFiles(files);
        },
    },
}
</script>
<style scoped lang='scss'>
.file-container {
    width: 100%;
    height: 100%;
    position: relative;

    .file-list {
        position: absolute;
        top: 0;
        width: 100%;
        // height: calc(100% - 32px);

        &::before {
            height: 0;
        }

        .status {
            display: flex;
            align-items: center;
            justify-content: center;

            .status-img {
                width: 14px;
                height: 14px;
                margin-right: 8px;
            }

            .status-text {}
        }
    }

    .table-upload {
        position: absolute;
        bottom: 0;
        height: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: rgba(39, 121, 255, 0.9);
        line-height: 20px;

        .upload-img {
            width: 32px;
            height: 32px;
        }
    }

    .file-upload {
        position: absolute;
        top: 46px;
        width: 100%;
        height: calc(100% - 46px);
    }
}


.loading {
    display: block;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotateZ(0deg);
        /*从0度开始*/
    }

    100% {
        transform: rotateZ(360deg);
        /*360度结束*/
    }
}
</style>